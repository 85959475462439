<template>
	<el-row style="padding-bottom: 20px;">
		<el-col :xs="0" :sm="0" :md="0" :lg="5" :xl="5"></el-col>
		<el-col :xs="24" :sm="24" :md="24" :lg="14" :xl="14">
			<Breadcrumb :title="$t('menu.contactUs')" :titleList="[$t('menu.home'), $t('menu.contactUs')]"></Breadcrumb>
			<el-row>
				<el-col :xs="24" :sm="24" :md="24" :lg="18" :xl="18">
					<table>
						<tr v-for="(item, i) in contact.items" :key="i">
							<td style="width: 5em; padding-bottom: 10px; margin-top: 10px; vertical-align: top;">
								{{ $t('contactUs.' + item.key) }}:
							</td>
							<td style="padding-bottom: 10px; margin-top: 10px;">
								<div v-for="(value, j) in item.value" :key="j"
									:style="{ paddingTop: j === 0 ? '0' : '10px' }">
									<div>{{ $t('contactUs.' + value) }};</div>
								</div>
							</td>
						</tr>
					</table>
				</el-col>
				<el-col  :xs="0" :sm="0" :md="0" :lg="6" :xl="6">
					<el-image src="/ContactUs/contactUs.png" fit="contain" class="animate__animated animate__shakeY"
						style="width: 150px; float: right; margin: 0 5% 0 0"></el-image>
				</el-col>
			</el-row>
			<el-image class="animate__animated animate__pulse" v-for="(image, index) in contact.images" :key="index"
				:src="image" fit="contain"></el-image>
		</el-col>
		<el-col :xs="0" :sm="0" :md="0" :lg="5" :xl="5"></el-col>
	</el-row>
</template>

<script>
import contact from "/public/Json/contactUs.json";
import Breadcrumb from "../../components/Breadcrumb.vue";

export default {
	name: "Contact",
	components: {
		Breadcrumb,
	},
	data() {
		return {
			contact,
		};
	}
};
</script>